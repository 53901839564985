import axios from "axios";
import config from "../config";

let practices = new Object({
  fetchPracticeEvents: (practiceId, ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/fetchPracticeEvents",
        data: { practiceId: practiceId, ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.events,
            totalCount: resp.data.responseData.count,
          });
        })

        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchServices: (practiceId, ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/fetchPracticeServices",
        data: { practiceId: practiceId, ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.services,
            totalCount: resp.data.responseData.count,
          });
        })

        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchAttachments: (practiceId, ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/fetchPracticeAttachments",
        data: { practiceId: practiceId, ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.attachments,
            totalCount: resp.data.responseData.count,
          });
        })

        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchDocuments: (practiceId, ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/fetchPracticeDocuments",
        data: { practiceId: practiceId, ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.documents,
            totalCount: resp.data.responseData.count,
          });
        })

        .catch((err) => {
          reject(err);
        });
    });
  },

  addAttachment: (practiceId, path) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/addAttachment",
        data: { practiceId: practiceId, path: path },
        method: "POST",
      })
        .then((resp) => {
          resolve({ lastInsertId: resp.data.responseData.lastInsertId });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeAttachment: (practiceId, attachmentId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/removeAttachment",
        data: { practiceId: practiceId, id: attachmentId },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addPracticeEvent: (practiceId, event) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/addPracticeEvent",
        data: { practiceId: practiceId, event: event },
        method: "POST",
      })
        .then((resp) => {
          resolve({ lastInsertId: resp.data.responseData.lastInsertId });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  closePracticeEvent: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/closePracticeEvent",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  openPracticeEvent: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/openPracticeEvent",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePracticeEventStatus: (id, status) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/updatePracticeEventStatus",
        data: { id: id, status: status },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deletePracticeEvent: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/deletePracticeEvent",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePracticeEvent: (event) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/updatePracticeEvent",
        data: { event: event },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addPracticeService(practiceId, service) {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/addPracticeService",
        data: { practiceId: practiceId, service: service },
        method: "POST",
      })
        .then((resp) => {
          resolve({ lastInsertId: resp.data.responseData.lastInsertId });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deletePracticeService: (practiceId, id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/deletePracticeService",
        data: { practiceId: practiceId, id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addPracticeDocument(practiceId, document) {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/addPracticeDocument",
        data: { practiceId: practiceId, document: document },
        method: "POST",
      })
        .then((resp) => {
          resolve({ lastInsertId: resp.data.responseData.lastInsertId });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deletePracticeDocument: (practiceId, id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/deletePracticeDocument",
        data: { practiceId: practiceId, id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchFilterEvents: (filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/fetchFilterEvents",
        data: { ctx: { filter: filter } },
        method: "POST",
      })
        .then((resp) => {
          resolve({ events: resp.data.responseData.events });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchFilterServices: (filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/fetchFilterServices",
        data: { ctx: { filter: filter } },
        method: "POST",
      })
        .then((resp) => {
          resolve({ services: resp.data.responseData.events });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchEvents: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/fetchEvents",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.events,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addEvent: (event) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/addEvent",
        data: { event: event },
        method: "POST",
      })
        .then((resp) => {
          resolve({ lastInsertId: resp.data.responseData.lastInsertId });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateEvent: (event) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/updateEvent",
        data: { event: event },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeEvent: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/deleteEvent",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchNextId: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/nextId",
        method: "POST",
      })
        .then((resp) => {
          resolve({
            nextId: resp.data.responseData.nextId,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPractices: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/list",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.practices,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addPractice: (practice) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/add",
        data: { practice: practice },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePractice: (practice) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/update",
        data: { practice: practice },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removePractice: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/delete",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePracticeStatus: (id, status) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/practices/updatePracticeStatus",
        data: { id: id, status: status },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
});

export default practices;
