<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <messageBox ref="msgBox" />
        <chooseCustomer
          @customerConfigured="onCustomerChanged"
          ref="chooseCustomer"
        />
        <quoteEditor
          ref="quoteEditor"
          v-model="quoteEditorDialog"
          @quoteConfigured="handleQuote"
        />
        <setStringWithSelect ref="setStringWithSelect" />

        <v-btn v-on:click="addQuote(0)" color="info">{{
          $t("quotesmng-add-quote")
        }}</v-btn>

        <base-material-card
          color="primary"
          icon="mdi-archive-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("quotesmng-title") }}
            </div>
          </template>
          <div style="height: 10px" />

          <div class="vertical-spacer" />
          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="quotes"
            :options.sync="options"
            :server-items-length="totalQuotes"
            :loading="loadingQuotes"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.id="{ item }">
              {{ getQuoteNumber(item) }}
            </template>
            <template v-slot:item.customer_name="{ item }">
              {{
                item.business_name
                  ? item.business_name
                  : item.first_name + " " + item.last_name
              }}
              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="primary"
                    @click="reassignQute(item)"
                    v-on="on"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  ></template
                ><span>{{ $t("quotesmng-reassign-quote") }}</span></v-tooltip
              >
            </template>

            <template v-slot:item.created_by="{ item }">
              <v-avatar
                size="36px"
                v-if="
                  AvatarServices.checkGravatar(item, 'created_by') &&
                  AvatarServices.getGravatar(item, 'created_by')
                "
              >
                <v-img
                  :src="AvatarServices.getGravatar(item, 'created_by')"
                  :alt="AvatarServices.getUsername(item, 'created_by')"
                />
              </v-avatar>

              <v-avatar
                v-else-if="AvatarServices.getProfileLetters(item, 'created_by')"
                color="blue"
                size="36px"
              >
                <span class="profile-text">{{
                  AvatarServices.getProfileLetters(item, "created_by")
                }}</span>
              </v-avatar>
              {{ AvatarServices.getUsername(item, "created_by") }}
            </template>
            <template v-slot:item.status="{ item }">
              {{ quoteStatesOptions[item.status].name }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div style="min-width: 120px">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click="duplicateQuote(item)"
                      v-on="on"
                      ><v-icon>mdi-content-duplicate</v-icon></v-btn
                    ></template
                  ><span>{{ $t("quotesmng-duplicate-quote") }}</span></v-tooltip
                >

                <v-tooltip v-if="$store.state.hasRight('198')" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click="downloadQuote(item)"
                      v-on="on"
                      ><v-icon>mdi-arrow-down</v-icon></v-btn
                    ></template
                  ><span>{{ $t("quote-action-download") }}</span></v-tooltip
                >

                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click="editQuote(item)"
                      v-on="on"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{ $t("quote-action-pencil") }}</span></v-tooltip
                >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="error"
                      @click="deleteItem(item)"
                      v-on="on"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    ></template
                  ><span>{{ $t("quote-action-delete") }}</span></v-tooltip
                >
              </div>
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import messageBox from "../components/MessageBox";
import quotesManager from "../apis/quotes";
import quoteEditor from "../components/QuoteEditor.vue";
import AvatarServices from "../services/avatars";
import setStringWithSelect from "../components/SetStringWithSelect";
import chooseCustomer from "../components/ChooseCustomer";

export default {
  data() {
    return {
      AvatarServices: AvatarServices,
      quoteStatesOptions: staticData.quotesStates,
      eventTimelineDialog: false,
      fetchingEvents: false,
      options: {
        sortBy: ["id"],
        sortDesc: [true],
      },
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      perPage: staticData.defautPerPage,
      headers: [],
      quotes: [],
      totalQuotes: 0,
      loadingQuotes: false,
      loadingData: false,
      quoteEditorDialog: false,
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("quotesmng-tbl-number"),
        align: "left",
        sortable: true,
        value: "id",
      },
      {
        text: this.$t("quotesmng-tbl-name"),
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: this.$t("quotesmng-tbl-customer"),
        align: "left",
        sortable: true,
        value: "customer_name",
      },
      {
        text: this.$t("quotesmng-tbl-status"),
        align: "left",
        sortable: true,
        value: "status",
      },
      {
        text: this.$t("quotesmng-tbl-created-by"),
        align: "left",
        sortable: true,
        value: "created_by",
      },
      { value: "actions", sortable: false, text: "", width: "64px" },
    ];
  },

  components: {
    messageBox,
    quoteEditor,
    setStringWithSelect,
    chooseCustomer,
  },

  watch: {
    options: {
      handler() {
        this.fetchQuotes();
      },
      deep: true,
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.t();
        }, 300);
      },
    },

    filterByProductCategory: {
      handler() {
        this.t();
      },
    },
  },

  methods: {
    getQuoteNumber(item) {
      let id = item.id.toString().padStart(3, "0");
      id = id + " - " + new Date(item.creation_date).getFullYear();

      return id;
    },

    downloadQuote(item) {
      quotesManager
        .downloadQuote(item.id, this.$store.state.user.locale)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },

    changeQuoteStatus(item) {
      let states = [...staticData.quotesStates];

      this.$refs.setStringWithSelect
        .show(
          this.$t("quote-edit-status-title"),
          this.$t("quote-edit-status-hint"),
          states,
          item.status
        )
        .then((event) => {
          quotesManager
            .updateQuoteStatus(item.id, event.value)
            .then(() => {
              this.fetchQuotes();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    fetchQuotes() {
      this.loadingQuotes = true;
      this.totalQuotes = 0;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        ctx.filterByProductCategory = this.filterByProductCategory;
        ctx.showHidden = true;
        quotesManager
          .fetchQuotes(ctx)
          .then((result) => {
            this.totalQuotes = result.totalCount;
            this.quotes = result.items;
            this.loadingQuotes = false;
            resolve(result);
          })
          .catch((err) => {
            this.loadingQuotes = false;
            console.log(err);
            reject();
          });
      });
    },

    handleQuote(event) {
      if (event.editingQuote) {
        let quote = event.quote;
        delete quote.services;
        quotesManager
          .updateQuote(quote)
          .then(() => {
            this.fetchQuotes();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let quote = event.quote;
        quotesManager
          .addQuote(quote)
          .then(() => {
            this.fetchQuotes();
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.quoteEditorDialog = false;
    },

    deleteItem(item) {
      let productId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("quotesmng-delete-title"),
          this.$t("quotesmng-delete-text")
        )
        .then(() => {
          quotesManager
            .removeQuote(productId)
            .then(() => {
              this.fetchQuotes();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editQuote(item) {
      let quote = JSON.parse(JSON.stringify(item));
      this.$refs.quoteEditor.setModalQuote(quote, true);
      this.quoteEditorDialog = true;
    },

    addQuote() {
      quotesManager.fetchNextId().then((res) => {
        this.$refs.quoteEditor.setModalQuote(
          {
            id: res.nextId,
          },
          false
        );
        this.quoteEditorDialog = true;
      });
    },

    duplicateQuote(item) {
      quotesManager
        .duplicateQuote(item.id)
        .then(() => {
          this.fetchQuotes();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    reassignQute(item) {
      this.$refs.chooseCustomer.show(item);
    },
    onCustomerChanged(event) {
      console.log(event);
      quotesManager
        .reassignQuote(event.context.id, event.customer_id)
        .then(() => {
          this.fetchQuotes();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
