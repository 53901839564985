import axios from "axios";
import config from "../config";
import localStorageService from "../services/localStorageService";

let quotes = new Object({
  fetchServices: (quoteId, ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/fetchQuoteServices",
        data: { quoteId: quoteId, ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.services,
            totalCount: resp.data.responseData.count,
          });
        })

        .catch((err) => {
          reject(err);
        });
    });
  },

  addQuoteService(quoteId, service) {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/addQuoteService",
        data: { quoteId: quoteId, service: service },
        method: "POST",
      })
        .then((resp) => {
          resolve({ lastInsertId: resp.data.responseData.lastInsertId });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateQuoteService: (quoteId, service) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/updateQuoteService",
        data: { quoteId: quoteId, service: service },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  flushQuoteServicesPriority: (services, quoteId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/flushQuoteServicesPriority",
        data: { services: services, quoteId: quoteId },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteQuoteService: (quoteId, id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/deleteQuoteService",
        data: { quoteId: quoteId, id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchFilterServices: (filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/fetchFilterServices",
        data: { ctx: { filter: filter } },
        method: "POST",
      })
        .then((resp) => {
          resolve({ services: resp.data.responseData.events });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchNextId: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/nextId",
        method: "POST",
      })
        .then((resp) => {
          resolve({
            nextId: resp.data.responseData.nextId,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchQuotes: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/list",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.quotes,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addQuote: (quote) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/add",
        data: { quote: quote },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateQuote: (quote) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/update",
        data: { quote: quote },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeQuote: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/delete",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  duplicateQuote: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/duplicate",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  reassignQuote: (id, customer_id) => {
    console.log("reassignQuote", id, customer_id);
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quotes/reassign",
        data: { id: id, customer_id: customer_id },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateQuoteStatus: (id, status) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/quote/updateQuoteStatus",
        data: { id: id, status: status },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadQuote: (id) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/quotes/quote-" +
          encodeURIComponent(id) +
          ".pdf?id=" +
          encodeURIComponent(id) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },
});

export default quotes;
