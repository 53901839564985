<template>
  <v-dialog persistent overlay-opacity="0.6" max-width="1600" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <setStringWithSelect ref="setStringWithSelect" />
      <v-dialog ref="dateDialog" v-model="dateModal" width="290px">
        <v-date-picker
          @input="saveDateDialog()"
          v-model="dateDialogValue"
          scrollable
          :first-day-of-week="1"
          :locale="$store.state.user.locale"
        >
        </v-date-picker>
      </v-dialog>
      <v-dialog
        ref="forecastDateDialog"
        v-model="forecastDateModal"
        width="290px"
      >
        <v-date-picker
          @input="saveForecastDateDialog()"
          v-model="forecastDateDialogValue"
          scrollable
          :first-day-of-week="1"
          :locale="$store.state.user.locale"
        >
        </v-date-picker>
      </v-dialog>
      <messageBox ref="msgBox" />
      <setCurrency ref="setCurrency" />
      <setQuantity ref="setQuantity" />
      <setString ref="setString" />
      <FileUploader
        ref="fileUploader"
        @uploadCompleted="handleUploadCompleted"
        v-model="fileUploader"
      />
      <addEvent ref="addEvent" @addEvent="handleEvent" />
      <addService ref="addService" @addService="handleService" />
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-cube-send</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("practice-edit-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div class="vertical-spacer" />

            <div v-if="!customer && user">
              {{ $t("practice-edit-customer") }}
              <div>
                <v-autocomplete
                  style="max-width: 500px; margin: auto"
                  dense
                  clearable
                  no-filter
                  v-model="customer"
                  :items="customers"
                  :loading="isLoadingCustomers"
                  :search-input.sync="searchCustomersInput"
                  append-icon="mdi-magnify"
                  :label="$t('practice-edit-customer-search')"
                  item-text="display_name"
                  item-value="id"
                  hide-details
                  single-line
                  outlined
                  return-object
                >
                  <template v-slot:item="data">
                    <div style="width: 100%">
                      <v-icon :color="data.item.reference_color"
                        >mdi-circle</v-icon
                      >
                      <span class="text-center body-2 font-weight-light">{{
                        data.item.name
                      }}</span>
                    </div>
                  </template>
                </v-autocomplete>
              </div>
            </div>

            <ValidationObserver
              ref="obs"
              v-slot="{ invalid, validated }"
              v-if="customer"
            >
              <v-row class="text-center" dense v-if="customer">
                <v-col>
                  {{ $t("practice-edit-customer-name") }}<BR />
                  <span class="font-weight-bold">{{
                    customer.business_name
                      ? customer.business_name
                      : customer.first_name + " " + customer.last_name
                  }}</span>
                </v-col>
                <v-col>
                  {{ $t("practice-edit-customer-price-list") }}<BR />
                  <span class="font-weight-bold">{{
                    customer.price_list
                  }}</span>
                </v-col>
              </v-row>

              <div v-if="customer">
                <div style="height: 10px" />
                <v-row>
                  <v-col>
                    <v-row dense
                      ><v-col>
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field
                            outlined
                            dense
                            :immediate="true"
                            v-model="practice.name"
                            :label="$t('practice-edit-name')"
                            :error-messages="errors"
                            :success="valid"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense
                      ><v-col>
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field
                            outlined
                            dense
                            :immediate="true"
                            @click="openDateDialog()"
                            :value="practice.creation_date | toLocaleDate"
                            :label="$t('practice-edit-select-date')"
                            :error-messages="errors"
                            :success="valid"
                            prepend-icon="mdi-calendar"
                            readonly
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row dense>
                      <v-col>
                        <v-text-field
                          outlined
                          dense
                          :immediate="true"
                          @click="openForecastDateDialog()"
                          :value="
                            practice.forecast_completion_date | toLocaleDate
                          "
                          :label="$t('practice-edit-select-end-date')"
                          prepend-icon="mdi-calendar"
                          readonly
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors, valid }"
                        >
                          <v-select
                            hide-details
                            :label="$t('practice-edit-hint-state')"
                            outlined
                            dense
                            v-model="practice.status"
                            item-text="name"
                            item-value="id"
                            prepend-icon="mdi-refresh"
                            :items="practiceStatesOptions"
                            :error-messages="errors"
                            :success="valid"
                          >
                          </v-select
                        ></ValidationProvider>
                      </v-col> </v-row
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col
                    ><v-card outlined
                      ><v-card-subtitle
                        >Allegati
                        <v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              class="ml-2"
                              x-small
                              color="primary"
                              @click.stop="addAttachment()"
                              v-on="on"
                              ><v-icon>mdi-plus</v-icon></v-btn
                            ></template
                          ><span>{{
                            $t("practices-add-attachment")
                          }}</span></v-tooltip
                        ></v-card-subtitle
                      ><v-card-text>
                        <v-data-table
                          :items-per-page="perPage"
                          :must-sort="true"
                          :headers="attachmentsHeaders"
                          :items="practice.attachments"
                          :options.sync="attachmentsOptions"
                          :server-items-length="practice.totalAttachments"
                          :loading="fetchingAttachments"
                          :footer-props="dataTableFooterOptions"
                        >
                          <template v-slot:item.path="{ item }">
                            {{ item.path.split("/")[3] }}
                          </template>
                          <template v-slot:item.time="{ item }">
                            {{ item.time | toLocaleDateTime }}
                          </template>
                          <template v-slot:item.created_by="{ item }">
                            <v-avatar
                              size="36px"
                              v-if="
                                AvatarServices.checkGravatar(
                                  item,
                                  'attach_created_by'
                                ) &&
                                AvatarServices.getGravatar(
                                  item,
                                  'attach_created_by'
                                )
                              "
                            >
                              <v-img
                                :src="
                                  AvatarServices.getGravatar(
                                    item,
                                    'attach_created_by'
                                  )
                                "
                                :alt="
                                  AvatarServices.getUsername(
                                    item,
                                    'attach_created_by'
                                  )
                                "
                              />
                            </v-avatar>

                            <v-avatar
                              v-else-if="
                                AvatarServices.getProfileLetters(
                                  item,
                                  'attach_created_by'
                                )
                              "
                              color="blue"
                              size="36px"
                            >
                              <span class="profile-text">{{
                                AvatarServices.getProfileLetters(
                                  item,
                                  "attach_created_by"
                                )
                              }}</span>
                            </v-avatar>

                            {{
                              AvatarServices.getUsername(
                                item,
                                "attach_created_by"
                              )
                            }}
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <div style="min-width: 120px">
                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="green"
                                    @click="downloadAttachment(item)"
                                    v-on="on"
                                    ><v-icon>mdi-arrow-down</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("practice-attachment-action-download")
                                }}</span></v-tooltip
                              >
                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="error"
                                    @click="deleteAttachment(item)"
                                    v-on="on"
                                    ><v-icon>mdi-delete</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("practice-attachment-action-delete")
                                }}</span></v-tooltip
                              >
                            </div>
                          </template>
                        </v-data-table>
                      </v-card-text></v-card
                    >
                  </v-col>
                  <v-col
                    ><v-card outlined
                      ><v-card-subtitle
                        >Documenti
                        <v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              class="ml-2"
                              x-small
                              color="primary"
                              @click.stop="addDocument()"
                              v-on="on"
                              ><v-icon>mdi-plus</v-icon></v-btn
                            ></template
                          ><span>{{
                            $t("practices-add-attachment")
                          }}</span></v-tooltip
                        ></v-card-subtitle
                      ><v-card-text>
                        <v-data-table
                          :items-per-page="perPage"
                          :must-sort="true"
                          :headers="documentsHeaders"
                          :items="practice.documents"
                          :options.sync="documentsOptions"
                          :server-items-length="practice.totalDocuments"
                          :loading="fetchingDocuments"
                          :footer-props="dataTableFooterOptions"
                        >
                          <template v-slot:item.created_by="{ item }">
                            <v-avatar
                              size="36px"
                              v-if="
                                AvatarServices.checkGravatar(
                                  item,
                                  'document_created_by'
                                ) &&
                                AvatarServices.getGravatar(
                                  item,
                                  'document_created_by'
                                )
                              "
                            >
                              <v-img
                                :src="
                                  AvatarServices.getGravatar(
                                    item,
                                    'document_created_by'
                                  )
                                "
                                :alt="
                                  AvatarServices.getUsername(
                                    item,
                                    'document_created_by'
                                  )
                                "
                              />
                            </v-avatar>

                            <v-avatar
                              v-else-if="
                                AvatarServices.getProfileLetters(
                                  item,
                                  'document_created_by'
                                )
                              "
                              color="blue"
                              size="36px"
                            >
                              <span class="profile-text">{{
                                AvatarServices.getProfileLetters(
                                  item,
                                  "document_created_by"
                                )
                              }}</span>
                            </v-avatar>

                            {{
                              AvatarServices.getUsername(
                                item,
                                "document_created_by"
                              )
                            }}
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <div style="min-width: 120px">
                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="error"
                                    @click="deleteDocument(item)"
                                    v-on="on"
                                    ><v-icon>mdi-delete</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("practice-document-action-delete")
                                }}</span></v-tooltip
                              >
                            </div>
                          </template>
                        </v-data-table>
                      </v-card-text></v-card
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    ><v-card outlined
                      ><v-card-subtitle
                        >Eventi e scadenze
                        <v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              class="ml-2"
                              x-small
                              color="primary"
                              @click.stop="addNewEvent()"
                              v-on="on"
                              ><v-icon>mdi-plus</v-icon></v-btn
                            ></template
                          ><span>{{
                            $t("practices-add-event")
                          }}</span></v-tooltip
                        > </v-card-subtitle
                      ><v-card-text>
                        <v-data-table
                          :items-per-page="perPage"
                          :must-sort="true"
                          :headers="eventHeaders"
                          :items="practice.events"
                          :options.sync="eventsOptions"
                          :server-items-length="practice.totalEvents"
                          :loading="fetchingEvents"
                          :footer-props="dataTableFooterOptions"
                        >
                          <template v-slot:item.name="{ item }">
                            <v-icon class="mr-1" :color="item.color"
                              >mdi-circle</v-icon
                            >{{ item.name ? item.name : item.event_text }}
                          </template>

                          <template v-slot:item.assigned_to="{ item }">
                            <v-avatar
                              size="36px"
                              v-if="
                                AvatarServices.checkGravatar(
                                  item,
                                  'assigned_to'
                                ) &&
                                AvatarServices.getGravatar(item, 'assigned_to')
                              "
                            >
                              <v-img
                                :src="
                                  AvatarServices.getGravatar(
                                    item,
                                    'assigned_to'
                                  )
                                "
                                :alt="
                                  AvatarServices.getUsername(
                                    item,
                                    'assigned_to'
                                  )
                                "
                              />
                            </v-avatar>

                            <v-avatar
                              v-else-if="
                                AvatarServices.getProfileLetters(
                                  item,
                                  'assigned_to'
                                )
                              "
                              color="blue"
                              size="36px"
                            >
                              <span class="profile-text">{{
                                AvatarServices.getProfileLetters(
                                  item,
                                  "assigned_to"
                                )
                              }}</span>
                            </v-avatar>

                            {{
                              AvatarServices.getUsername(item, "assigned_to")
                            }}
                          </template>

                          <template v-slot:item.created_by="{ item }">
                            <v-avatar
                              size="36px"
                              v-if="
                                AvatarServices.checkGravatar(
                                  item,
                                  'created_by'
                                ) &&
                                AvatarServices.getGravatar(item, 'created_by')
                              "
                            >
                              <v-img
                                :src="
                                  AvatarServices.getGravatar(item, 'created_by')
                                "
                                :alt="
                                  AvatarServices.getUsername(item, 'created_by')
                                "
                              />
                            </v-avatar>

                            <v-avatar
                              v-else-if="
                                AvatarServices.getProfileLetters(
                                  item,
                                  'created_by'
                                )
                              "
                              color="blue"
                              size="36px"
                            >
                              <span class="profile-text">{{
                                AvatarServices.getProfileLetters(
                                  item,
                                  "created_by"
                                )
                              }}</span>
                            </v-avatar>

                            {{ AvatarServices.getUsername(item, "created_by") }}
                          </template>
                          <template v-slot:item.completed_by="{ item }">
                            <v-avatar
                              size="36px"
                              v-if="
                                AvatarServices.checkGravatar(
                                  item,
                                  'completed_by'
                                ) &&
                                AvatarServices.getGravatar(item, 'completed_by')
                              "
                            >
                              <v-img
                                :src="
                                  AvatarServices.getGravatar(
                                    item,
                                    'completed_by'
                                  )
                                "
                                :alt="
                                  AvatarServices.getUsername(
                                    item,
                                    'completed_by'
                                  )
                                "
                              />
                            </v-avatar>

                            <v-avatar
                              v-else-if="
                                AvatarServices.getProfileLetters(
                                  item,
                                  'completed_by'
                                )
                              "
                              color="blue"
                              size="36px"
                            >
                              <span class="profile-text">{{
                                AvatarServices.getProfileLetters(
                                  item,
                                  "completed_by"
                                )
                              }}</span>
                            </v-avatar>

                            {{
                              AvatarServices.getUsername(item, "completed_by")
                            }}
                          </template>
                          <template v-slot:item.status="{ item }">
                            {{ getItemStatus(item) }}
                            <v-tooltip bottom
                              ><template v-slot:activator="{ on }"
                                ><v-btn
                                  x-small
                                  text
                                  icon
                                  color="primary"
                                  @click="editEventState(item)"
                                  v-on="on"
                                  ><v-icon>mdi-pencil</v-icon></v-btn
                                ></template
                              ><span>{{
                                $t("practice-event-action-pencil")
                              }}</span></v-tooltip
                            >
                          </template>
                          <template v-slot:item.expire_on="{ item }">
                            {{ item.expire_on }}
                          </template>
                          <template v-slot:item.created_on="{ item }">
                            {{ item.created_on | toLocaleDateTime }}
                          </template>
                          <template v-slot:item.completed_on="{ item }">
                            {{ item.completed_on | toLocaleDateTime }}
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <div style="min-width: 120px">
                              <v-tooltip v-if="!item.completed_by" bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="success"
                                    @click="closeEvent(item)"
                                    v-on="on"
                                    ><v-icon>mdi-check</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("practice-event-action-close")
                                }}</span></v-tooltip
                              >
                              <v-tooltip v-if="item.completed_by" bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="red"
                                    @click="openEvent(item)"
                                    v-on="on"
                                    ><v-icon
                                      >mdi-arrow-right-circle-outline</v-icon
                                    ></v-btn
                                  ></template
                                ><span>{{
                                  $t("practice-event-action-open")
                                }}</span></v-tooltip
                              >
                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="error"
                                    @click="removeEvent(item)"
                                    v-on="on"
                                    ><v-icon>mdi-delete</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("practice-event-action-delete")
                                }}</span></v-tooltip
                              >
                            </div>
                          </template>
                        </v-data-table>
                      </v-card-text></v-card
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    ><v-card outlined
                      ><v-card-subtitle
                        >Prestazioni
                        <v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              class="ml-2"
                              x-small
                              color="primary"
                              @click.stop="addService()"
                              v-on="on"
                              ><v-icon>mdi-plus</v-icon></v-btn
                            ></template
                          ><span>{{
                            $t("practices-add-service")
                          }}</span></v-tooltip
                        ></v-card-subtitle
                      ><v-card-text>
                        <v-data-table
                          :items-per-page="perPage"
                          :must-sort="true"
                          :headers="servicesHeaders"
                          :items="practice.services"
                          :options.sync="servicesOptions"
                          :server-items-length="practice.totalServices"
                          :loading="fetchingServices"
                          :footer-props="dataTableFooterOptions"
                        >
                          <template v-slot:item.price="{ item }">
                            {{ item.price | toCurrencySymbol }}
                          </template>

                          <template v-slot:item.quantity="{ item }">
                            {{ item.quantity.toFixed(2) }}
                          </template>

                          <template v-slot:item.vat_percent="{ item }">
                            {{ item.vat_percent.toFixed(2) }}
                          </template>
                          <template v-slot:item.total="{ item }">
                            {{
                              (item.price * item.quantity +
                                (item.price *
                                  item.quantity *
                                  item.vat_percent) /
                                  100.0)
                                | toCurrencySymbol
                            }}
                          </template>

                          <template v-slot:item.actions="{ item }">
                            <div style="min-width: 120px">
                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="red"
                                    @click="deleteService(item)"
                                    v-on="on"
                                    ><v-icon>mdi-delete</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("practice-service-delete-service")
                                }}</span></v-tooltip
                              >
                            </div>
                          </template></v-data-table
                        >
                      </v-card-text></v-card
                    >
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-textarea
                      height="100"
                      outlined
                      v-model="practice.notes"
                      :label="$t('practice-edit-hint-notes')"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <div></div>

                <div style="height: 10px" />
                <v-progress-circular
                  v-if="pushing_practice"
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="primary"
                ></v-progress-circular>

                <v-btn
                  v-if="!pushing_practice"
                  width="120"
                  :disabled="!practice.creation_date || invalid"
                  v-on:click="confirm()"
                  color="primary"
                  >{{ $t("gbl-ok") }}</v-btn
                >

                <v-btn width="120" v-on:click="cancel()" color="secondary">{{
                  $t("gbl-cancel")
                }}</v-btn>
              </div>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AvatarServices from "../services/avatars";
import FileUploader from "../components/FileUploader";
import customersManager from "../apis/customers";
import productsManager from "../apis/products";
import staticData from "../apis/static";
import messageBox from "../components/MessageBox";
import setCurrency from "../components/SetCurrency";
import setQuantity from "../components/SetQuantity";
import setString from "../components/SetString";
import addEvent from "../components/AddEvent";
import addService from "../components/AddService";
import config from "../config";
import practicesManager from "../apis/practices";
import fileManager from "../apis/filemanager";

import setStringWithSelect from "../components/SetStringWithSelect";
import { randomBytes, createHash } from "crypto";

export default {
  data() {
    return {
      AvatarServices: AvatarServices,
      practice: {
        customer_id: null,
        user_id: null,
        status: 1,
        notes: "",
        name: null,
        forecast_completion_date: new Date().toISOString().substring(0, 10),
        creation_date: new Date().toISOString().substring(0, 10),
      },
      fileUploader: false,
      practiceStatesOptions: staticData.practicesStates,
      customer: null,
      customers: [],
      searchCustomersInput: null,
      isLoadingCustomers: false,
      editingPractice: false,
      dateModal: false,
      dateDialogValue: null,
      forecastDateModal: false,
      forecastDateDialogValue: null,
      user: null,
      users: [],
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      attachSelectionDialog: false,
      perPage: staticData.defautPerPage,
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
      servicesHeaders: [
        {
          text: this.$t("practice-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("practice-edit-tbl-price"),
          align: "left",
          sortable: true,
          value: "price",
        },
        {
          text: this.$t("practice-edit-tbl-quantity"),
          align: "left",
          sortable: true,
          value: "quantity",
        },
        {
          text: this.$t("practice-edit-tbl-vat"),
          align: "left",
          sortable: true,
          value: "vat_percent",
        },
        {
          text: this.$t("practice-edit-tbl-total"),
          align: "left",
          sortable: true,
          value: "total",
        },
        {
          text: this.$t("practice-edit-tbl-invoice"),
          align: "left",
          sortable: true,
          value: "invoice",
        },
        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      attachmentsHeaders: [
        {
          text: this.$t("practice-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "path",
        },

        {
          text: this.$t("practice-edit-tbl-created"),
          align: "left",
          sortable: true,
          value: "created_on",
        },
        {
          text: this.$t("practice-edit-tbl-created-by"),
          align: "left",
          sortable: true,
          value: "created_by",
        },
        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      documentsHeaders: [
        {
          text: this.$t("practice-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "name",
        },

        {
          text: this.$t("practice-edit-tbl-created"),
          align: "left",
          sortable: true,
          value: "created_on",
        },
        {
          text: this.$t("practice-edit-tbl-created-by"),
          align: "left",
          sortable: true,
          value: "created_by",
        },
        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      eventHeaders: [
        {
          text: this.$t("practice-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("practice-edit-tbl-status"),
          align: "left",
          sortable: true,
          value: "status",
        },
        {
          text: this.$t("practice-edit-tbl-created-on"),
          align: "left",
          sortable: true,
          value: "created_on",
        },
        {
          text: this.$t("practice-edit-tbl-assigned"),
          align: "left",
          sortable: true,
          value: "assigned_to",
        },
        {
          text: this.$t("practice-edit-tbl-expire"),
          align: "left",
          sortable: true,
          value: "expire_on",
        },
        {
          text: this.$t("practice-edit-tbl-created"),
          align: "left",
          sortable: true,
          value: "created_on",
        },
        {
          text: this.$t("practice-edit-tbl-created-by"),
          align: "left",
          sortable: true,
          value: "created_by",
        },
        {
          text: this.$t("practice-edit-tbl-completed-by"),
          align: "left",
          sortable: true,
          value: "completed_by",
        },
        {
          text: this.$t("practice-edit-tbl-completed-on"),
          align: "left",
          sortable: true,
          value: "completed_on",
        },
        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      eventsOptions: {
        sortBy: ["name"],
        sortDesc: [false],
        page: 1,
      },
      attachmentsOptions: {
        sortBy: ["path"],
        sortDesc: [false],
        page: 1,
      },
      documentsOptions: {
        sortBy: ["name"],
        sortDesc: [false],
        page: 1,
      },
      servicesOptions: {
        sortBy: ["name"],
        sortDesc: [false],
        page: 1,
      },
      headers: [
        {
          text: this.$t("practice-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "name",
        },
        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      filter: "",
      imageCache: {},
      pushing_practice: false,
      fetchingEvents: false,
      fetchingAttachments: false,
      fetchingDocuments: false,
      fetchingServices: false,
    };
  },

  props: ["value"],

  components: {
    ValidationObserver,
    ValidationProvider,
    messageBox,
    setCurrency,
    setQuantity,
    setString,
    addEvent,
    addService,
    FileUploader,
    setStringWithSelect,
  },

  watch: {
    eventsOptions: {
      handler() {
        this.fetchEvents();
      },
      deep: true,
    },

    attachmentsOptions: {
      handler() {
        this.fetchAttachments();
      },
      deep: true,
    },

    documentsOptions: {
      handler() {
        this.fetchDocuments();
      },
      deep: true,
    },
    servicesOptions: {
      handler() {
        this.fetchServices();
      },
      deep: true,
    },

    searchCustomersInput(val) {
      clearTimeout(this._searchCustomersInput);
      if (val) {
        this._searchCustomersInput = setTimeout(() => {
          this.isLoadingCustomseters = true;
          this.fetchFiltersCustomers(val)
            .then((res) => {
              this.customers = res.customers.map((x) => {
                if (x.business_name) x.display_name = x.business_name;
                else x.display_name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomers = false;
            })
            .catch(() => {
              this.isLoadingCustomers = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        if (!this.editingPractice) this.practice.customer_id = null;
      }
    },

    customer: {
      handler() {
        if (!this.editingPractice)
          this.practice.customer_id = this.customer ? this.customer.id : null;
        if (this.customer) {
          customersManager
            .fetchCustomer(this.practice.customer_id)
            .then(async (customer_results) => {
              customer_results.customer.days_exposure =
                this.practice.customer_id = customer_results.customer
                  ? customer_results.customer.id
                  : null;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
    },
  },

  mounted() {},

  computed: {},

  methods: {
    getItemStatus(item) {
      return staticData.eventsStates[item.status].name;
    },

    deleteAttachment(item) {
      this.$refs.msgBox
        .show(
          this.$t("practice-edit-remove-attach-text"),
          this.$t("practice-edit-remove-attach-title")
        )
        .then(() => {
          practicesManager
            .removeAttachment(this.practice.id, item.id)
            .then(() => {
              this.fetchAttachments();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    deleteDocument(item) {
      this.$refs.msgBox
        .show(
          this.$t("practice-edit-remove-document-text"),
          this.$t("practice-edit-remove-document-title")
        )
        .then(() => {
          practicesManager
            .deletePracticeDocument(this.practice.id, item.id)
            .then(() => {
              this.fetchDocuments();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    stripePath(path) {
      let index = path.lastIndexOf("/");
      if (index >= 0) {
        let res = path.substr(index + 1, path.length);
        if (res.length) return res;
      }
      return path;
    },

    downloadAttachment(item) {
      fileManager
        .downloadFile(item.path, this.stripePath(item.path))
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },

    handleUploadCompleted(res) {
      this.attachSelectionDialog = false;
      if (res) {
        practicesManager
          .addAttachment(this.practice.id, res.path)
          .then(() => {
            this.fetchAttachments();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteService(item) {
      this.$refs.msgBox
        .show(
          this.$t("practicesmng-service-delete-text"),
          this.$t("practicesmng-service-delete-title")
        )
        .then(() => {
          practicesManager
            .deletePracticeService(this.practice.id, item.id)
            .then(() => {
              this.fetchServices();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    removeEvent(item) {
      let id = item.id;
      this.$refs.msgBox
        .show(
          this.$t("practicesmng-event-delete-title"),
          this.$t("practicesmng-event-delete-text")
        )
        .then(() => {
          practicesManager
            .deletePracticeEvent(id)
            .then(() => {
              this.fetchEvents();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    closeEvent(item) {
      let id = item.id;
      this.$refs.msgBox
        .show(
          this.$t("practicesmng-event-close-title"),
          this.$t("practicesmng-event-close-text")
        )
        .then(() => {
          practicesManager
            .closePracticeEvent(id)
            .then(() => {
              this.fetchEvents();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    openEvent(item) {
      let id = item.id;
      this.$refs.msgBox
        .show(
          this.$t("practicesmng-event-open-title"),
          this.$t("practicesmng-event-open-text")
        )
        .then(() => {
          practicesManager
            .openPracticeEvent(id)
            .then(() => {
              this.fetchEvents();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editEventState(item) {
      let states = [...staticData.eventsStates];

      this.$refs.setStringWithSelect
        .show(
          this.$t("event-edit-status-title"),
          this.$t("event-edit-status-hint"),
          states,
          item.status
        )
        .then((event) => {
          practicesManager
            .updatePracticeEventStatus(item.id, event.value)
            .then(() => {
              this.fetchEvents();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    handleEvent(ev) {
      this.$refs.addEvent.closeDialog();
      practicesManager
        .addPracticeEvent(this.practice.id, ev.event)
        .then(() => {
          this.fetchEvents();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addNewEvent() {
      this.$refs.addEvent.show();
    },

    addAttachment() {
      const randomData = randomBytes(16).toString("hex");
      let hash = createHash("sha256").update(randomData).digest("hex");
      this.$refs.fileUploader.setPath(
        this.practice,
        "PracticesAttachments/" + this.practice.id.toString() + "/" + hash + "/"
      );
      this.fileUploader = true;
    },

    addDocument() {
      this.$refs.setString
        .show(
          this.$t("document-edit-status-title"),
          this.$t("document-edit-status-hint"),
          this.$t("document-edit-status-hint"),
          ""
        )
        .then((event) => {
          practicesManager
            .addPracticeDocument(this.practice.id, event.value)
            .then(() => {
              this.fetchDocuments();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    addService() {
      this.$refs.addService.show(true);
    },

    handleService(ev) {
      this.$refs.addService.closeDialog();
      practicesManager
        .addPracticeService(this.practice.id, ev.service)
        .then(() => {
          this.fetchServices();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchServices() {
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.servicesOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];

        this.fetchingServices = true;
        practicesManager
          .fetchServices(this.practice.id, ctx)
          .then((results) => {
            this.fetchingServices = false;
            this.practice.services = results.items;
            this.practice.totalServices = results.totalCount;
            resolve(results);
          })
          .catch((err) => {
            this.fetchingServices = false;
            console.log(err);
            reject();
          });
      });
    },

    fetchAttachments() {
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.attachmentsOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];

        this.fetchingAttachments = true;
        practicesManager
          .fetchAttachments(this.practice.id, ctx)
          .then((results) => {
            this.fetchingAttachments = false;
            this.practice.attachments = results.items;
            this.practice.totalAttachments = results.totalCount;
            resolve(results);
          })
          .catch((err) => {
            this.fetchingAttachments = false;
            console.log(err);
            reject();
          });
      });
    },

    fetchDocuments() {
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.documentsOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];

        this.fetchingDocuments = true;
        practicesManager
          .fetchDocuments(this.practice.id, ctx)
          .then((results) => {
            this.fetchingDocuments = false;
            this.practice.documents = results.items;
            this.practice.totalDocuments = results.totalCount;
            resolve(results);
          })
          .catch((err) => {
            this.fetchingDocuments = false;
            console.log(err);
            reject();
          });
      });
    },

    fetchEvents() {
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.eventsOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];

        this.fetchingEvents = true;
        practicesManager
          .fetchPracticeEvents(this.practice.id, ctx)
          .then((results) => {
            this.fetchingEvents = false;
            this.practice.events = results.items;
            this.practice.totalEvents = results.totalCount;
            resolve(results);
          })
          .catch((err) => {
            this.fetchingEvents = false;
            console.log(err);
            reject();
          });
      });
    },

    fetchFiltersCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then((results) => {
            results.customers = results.customers.map((x) => {
              if (!x.price_list) x.price_list = this.$t("gbl-none-male");
              return x;
            });
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchProductImage(item) {
      return new Promise((resolve, reject) => {
        if (item.fetchingImage) {
          if (!this.imageCache[item.id]) {
            productsManager
              .fetchProductImage(item.id)
              .then((results) => {
                if (results.image) {
                  item.fetchedImage = "data:image/png;base64," + results.image;
                  this.imageCache[item.id] = results.image;
                  item.fetchKey++;
                  item.fetchingImage = false;
                  resolve();
                } else {
                  item.fetchedImage = null;
                  item.fetchKey++;
                  item.fetchingImage = false;
                  resolve();
                }
              })
              .catch((err) => {
                console.log(err);
                item.fetchedImage = null;
                item.fetchKey++;
                item.fetchingImage = false;
                resolve();
              });
          } else {
            item.fetchedImage =
              "data:image/png;base64," + this.imageCache[item.id];
            item.fetchingImage = false;
            item.fetchKey++;
            resolve();
          }
        } else {
          reject();
        }
      });
    },

    saveDateDialog: function () {
      this.dateModal = false;
      this.forecastDateModal = false;
      this.practice.creation_date = this.dateDialogValue;
    },

    saveForecastDateDialog: function () {
      this.dateModal = false;
      this.forecastDateModal = false;
      this.practice.forecast_completion_date = this.forecastDateDialogValue;
    },

    openForecastDateDialog: function () {
      this.forecastDateModal = true;
    },

    openDateDialog: function () {
      this.dateModal = true;
    },

    confirm: function () {
      this.handleSubmit(false);
    },

    cancel: function () {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    closeDialog: function () {
      this.cancel();
    },

    handleSubmit() {
      this.$emit("practiceConfigured", {
        editingPractice: this.editingPractice,
        practice: this.practice,
      });
    },

    setModalPractice(practice, editing) {
      return new Promise(async (resolve, reject) => {
        if (!editing) {
          this.practice = Object.assign(
            {
              customer_id: null,
              user_id: null,
              status: 1,
              notes: "",
              name: null,
              forecast_completion_date: new Date()
                .toISOString()
                .substring(0, 10),
              creation_date: new Date().toISOString().substring(0, 10),
              events: [],
              services: [],
              attachments: [],
              documents: [],
              totalDocuments: 0,
              totalServices: 0,
              totalAttachments: 0,
              totalEvents: 0,
            },
            practice
          );
        } else {
          this.practice = Object.assign({}, practice);
          this.practice.totalEvents = 0;
          this.practice.events = [];
          this.practice.services = [];
          this.practice.attachments = [];
          this.practice.documents = [];
          this.practice.totalDocuments = 0;
          this.practice.totalServices = 0;
          this.practice.totalAttachments = 0;
          this.practice.totalEvents = 0;
        }

        this.editingPractice = editing;
        this.reset(editing)
          .then(() => {
            this.fetchEvents()
              .then(() => {
                this.fetchAttachments()
                  .then(() => {
                    this.fetchDocuments()
                      .then(() => {
                        this.fetchServices()
                          .then(() => {
                            resolve();
                          })
                          .catch((err) => {
                            console.log(err);
                            reject(err);
                          });
                      })
                      .catch((err) => {
                        console.log(err);
                        reject(err);
                      });
                  })
                  .catch((err) => {
                    console.log(err);
                    reject(err);
                  });
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    reset(editing) {
      this.customer = null;

      return new Promise(async (resolve, reject) => {
        // Fetch cart items and customer details as well as addresses
        if (editing) {
          customersManager
            .fetchCustomer(this.practice.customer_id)
            .then((result) => {
              this.customer = result.customer;
              resolve();
            })
            .catch((err) => {
              reject();
              console.log(err);
            });
        }

        if (!editing) {
          this.user = {
            id: this.$store.state.user.id,
            first_name: this.$store.state.user.first_name,
            last_name: this.$store.state.user.last_name,
          };
          this.practice.user_id = this.user.id;
        } else {
          for (let n = 0; n < this.users.length; n++) {
            if (this.users[n].id == this.practice.user_id) {
              this.user = this.users[n];
              break;
            }
          }
        }
        resolve();
      });
    },
  },
};
</script>

<style scoped>
.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
